@import "variables.scss";

/*
	Neste arquivo contém os arquivos referentes a estlizacao de componentes, como por exemplo cores, backgrounds, bordas e afins...
 */

// Variables
$text-black: #00001f;
$color-purple: linear-gradient(290deg, #a831ef, #fb29fc, #fd29fc);
$facebook-color: linear-gradient(300deg, #160748, #3b5998);
$white: white;
$text-muted: #515252;
$text-slogan: #535363;
$variavel: #fff;

// Default
body {
	// font-family: sans-serif;
	font-family: 'ZonaPro', sans-serif;
	font-size: .9rem;
	margin: 0;
	color: $textcolor;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
	font-family: 'ZonaPro-Bold', sans-serif;
}

h1 {
	font-size: 2rem;
}

/* Menu */

.nav ul li a {
	color: $text-black;
}

/* Text */

.text-black {
	color: $text-black;
}

.text-red {
	color: #FD8283 !important;
}

.text-pink {
	color: #f50673 !important;
}

.text-quicksand-muted {
	// font-family:   sans-serif;
	font-size: 18px;
	color: $text-muted;
	// font-weight: 500;
}

/* Buttons */

.btn-purple {
	color: $white !important;
	background-image: $color-purple;
	border-radius: 4px;
	font-size: 14px;
	// font-weight: 800;
	// font-family: sans-serif;
	transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, filter 0.1s linear;
	//transition: 1s filter linear, 1s -webkit-filter linear;
}

.btn-outlined {
	color: #f50673 !important;
	border: 1px solid #f50673 !important;
	background: $white;
	border-radius: 4px;
	padding: 4px;
	font-size: 14px;
	// font-weight: 800;
	// font-family: sans-serif;
	transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out, filter 0.1s linear;
	//transition: 1s filter linear, 1s -webkit-filter linear;
}

.btn-secondary {
	padding-top: 10px;
	padding-bottom: 10px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 14px;
	// font-weight: 800;
	text-align: center;
	border-radius: 4px;
	color: #f50673 !important;
	border: 1px solid #f50673;
	background-image: none;
	background-color: #fff !important;
}

.btn-event {
	color: white;
	background-color: rgba(0, 0, 31, .4)
}

.btn-muted {
	background-color: inherit;
	color: #b3b3c1;
	// font-family: 'ZonaPro-SemiBold', sans-serif;
	font-size: 14px;
}

.btn-purple:hover {
	box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.2);
	transform: scale(1.01, 1.01);
	transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.btn-purple:active {
	filter: brightness(90%);
	transition: filter 0.1s linear;
}

.btn-facebook {
	background-image: $facebook-color;
	color: $white;
	border: 0px;
	font-size: 16px;
	padding: 27px 25px 27px 25px;

	.fa {
		font-size: 25px;
		margin-right: 25px;
		border-right: 1px solid;
		border-color: rgba(11, 18, 25, .1);
	}
}


/* Alert messages */

/* The alert message box */
.alert {
	padding: 3px;
	margin-bottom: 15px;
}

.alert-success {
	color: green;
}

.alert-error {
	color: red;
}


/* The close button */
.closebtn,
.close_1fc7pjq {
	margin-left: 15px;
	color: #EBEBEB;
	// font-weight: bold;
	float: right;
	font-size: 22px;
	line-height: 20px;
	cursor: pointer;
	transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
	color: black;
}

/* Errors */

.help-hasError {
	float: right;
	font-size: 14px;
	color: red;
	padding-top: 4px;

	&:not(:empty) {
		/* Styles */
		margin-bottom: 20px;
	}
}

.help-hasSuccess {
	font-size: .8rem;
	letter-spacing: 0;
	color: green;
	padding: 0;
	margin: 3px 0 0 0;
}


/* Modal */

.ReactModal__Overlay {
	background-color: rgba(255, 255, 255, 0.9) !important;
}

.modal-modal-content {
	position: relative;
}

.main-shadow {
	text-shadow: 0.1px 1px 1px #00ff00, 1px 0.1px 0.2px #f50673;
}

.upper-case {
	text-transform: uppercase;
}

/* Lightbox */
.arrow_w83mnf-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo,
.arrow_w83mnf-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo {
	background: transparent !important;
}

/* Placeholder Content */

@-webkit-keyframes placeHolderShimmer {
	0% {
		background-position: -668px
	}

	100% {
		background-position: 668px
	}
}


.show-loading-animation.rect-shape,
.show-loading-animation.round-shape,
.show-loading-animation.text-row,
.show-loading-animation .rect-shape,
.show-loading-animation .round-shape,
.show-loading-animation .text-row {
	animation-duration: 5s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: #eeeeee;
	background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
	background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	-webkit-background-size: 800px 104px;
	background-size: 800px 104px;
	height: 96px;
	position: relative;
}

.show-loading-animation {
	max-width: 569px !important;
}

/* DatePicker */
.DateInput__display-text--focused {
	border-color: transparent !important;
	background-color: transparent !important;
	color: #000000 !important;
}

.CalendarDay--selected-start,
.CalendarDay--selected-end,
.CalendarDay--selected {
	background-image: linear-gradient(290deg, #a831ef, #fb29fc, #fd29fc) !important;
	border-color: transparent !important;
}

.DayPickerKeyboardShortcuts__show--bottom-right {
	display: none !important;
}

/* Toastfy */
.toastify {
	z-index: 9999 !important;
}


/* Rating component */
.dv-star-rating-full-star {
	color: #f50673 !important;
}

.dv-star-rating-empty-star {
	color: #f50673 !important;
}

.label {
	font-size: 14px;

	span {
		margin: 0 1%;
	}

	&.label-green {
		color: #52b33b;
	}

	&.label-warning {
		color: #f07939;
	}

	&.label-blue {
		color: #206db2;
	}
}

.tooltip {
	border: solid 1px #e5e5e5;
	background: #fff;
	padding: 2%;
	color: black;
}

.tooltip-helper {
	border: solid 1px #e5e5e5;
	color: #e5e5e5;
	border-radius: 50%;
	padding: 0 5px;

	&.reverse {
		background: #e5e5e5;
		color: white;
	}

	cursor: pointer;
}

.no-scrollbar {
	&::-webkit-scrollbar {
		display: none;
	}
}

.clear-inner {

	p,
	h1,
	h3,
	h4,
	h5,
	ul,
	li {
		margin: 0;
		line-height: unset;
	}
}

.reset-text {
	font-weight: revert !important;
	font-size: revert !important;
	color: revert !important;

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	ul,
	li,
	span {
		font-weight: revert !important;
		font-size: revert !important;
		color: revert !important;
	}
}

.link {
	cursor: pointer !important;
	color: #f50673;
	text-decoration: none;
}