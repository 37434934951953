.footer-end {
  width: 923px;
  margin-top: 170px;

  .navbar-brand {
    position: relative;

    .brand-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: 25%;
      left: 0;
      right: 0;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }

  @media (max-width: 500px) {
    margin-top: 50px;
  }

  ul {
    list-style: none;
  }

  li {
    margin-top: 15px;

    div {
      cursor: pointer;
    }
  }

  @media all and (max-width: 768px) {
    ul {
      padding-left: 0;
    }

    .footer-grid {
      flex-direction: column;

      ul {
        width: 100%;

        li {
          font-size: 20px;
          display: flex;
          justify-content: center;
        }
      }

    }
  }
}