.adn-icon {
	position: fixed;
	width: 60px;
	height: 65px;
	right: 20px;
	bottom: 20px;
	box-sizing: border-box;
	z-index: 99999;
	cursor: pointer;
}

.adn-icon-notification {
	width: 12px;
	height: 12px;
	background-color: red;
	border-radius: 50%;
	position: absolute;
	top: 6px;
	right: 6px;
	cursor: pointer;
}

@media screen and (max-width: 600px) {

	.adn-icon {
		width: 32px;
		height: 32px;
		padding: 3px;
		right: 0;
		bottom: 100px;
		box-shadow: 0px 0px 10px #00000030;
		background: white;
		border-radius: 4px 0 0 4px;
	}

	.adn-icon-notification {
		width: 6px;
		height: 6px;
	}
}