/* Menu */
.dropdown {
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
  z-index: 9999;
}

.dropdown--active .dropdown__content {
  display: block;
}


.menu-white {
  background-color: white;

}

.nav {
  position: relative;
  // font-family: Sans-Serif;
  // font-weight: 900;
  padding-top: 39px;
  font-size: 13px;

  .nav-border {
    border-bottom: 1px solid #e8e8eb;
    padding-bottom: 10px;
  }

  .account-dropdown {
    .dropdown__trigger {
      display: flex;
      align-items: center;
      justify-content: center;
      .user-label{
        width: 50px;
        height: 50px;
        margin: 0px 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .account-dropdown__link {
    // font-family:   sans-serif;
    display: grid !important;
    padding: 15px !important;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #d3dbdc;
    font-size: 16px;
    // font-weight: 500;
    text-align: left;
    color: #00001f;
  }

  .account-dropdown__quick-links {
    box-shadow: 0 0 90px 0 rgba(0, 0, 0, 0.3);
  }

  .account-dropdown__link:last-child {
    border-bottom: 0px;
  }

  .account-dropdown__link__anchor {
    text-align: center;
  }

  .account-dropdown__segment {
    padding-left: 0px;
    margin-top: 25px;
    width: 190px;
    margin-left: -83px;
    background: white;
  }


  ul {
    list-style: none;
  }

  ul li {
    height: 100%;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  .DayPicker__week-header{
    ul li {
      display: inline-block;
      padding: 0;
    }
  }

  .navbar-brand img {
    height: 30px;
    margin: 10px 0;
  }

  @media all and (max-width: 400px) {
    .navbar-brand img {
      height: 40px;
    }
  }

  .nav-icons {
    display: none;
    font-size: 35px;
    cursor: pointer;
  }

  .bm-burger-button {
    display: none;
  }

  .user-logged-image {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    border-radius: 50px;
    object-fit: cover;
    background: white;
    border: .1px solid rgba(0,0,0,.5);
  }

  .menu-search {
    padding-left: 40px;
    font-size: 16px;
  }


  .register {
    color: #fb29fc;
  }

  &.tiny {
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;

    .navbar-brand {
      flex: 1;
      a {
        display: flex;
        align-items: center;
        height: 100%;

        img {
          height: 30px;
          margin: 10px 0;
        }
      }
    }

    .nav-items {
      height: 50px;
      flex: 1;

      &.minimal-menu {
        display: flex!important;
        &.cols-2 {
          justify-content: space-between!important;
        }

        &.cols-1 {
          justify-content: flex-end!important;
        }
        img {
          height: 50px;
        }
      }

      &.logged {
        height: 100%;
      }

      #menuUl {
        padding: 0;
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .login-button {
    a {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // font-family: ZonaPro-SemiBold,sans-serif;
      // font-size: 14px;
      font-size: 13px;
      // font-weight: 800;
      text-align: center;
      border-radius: 4px;
      color: #f50673 !important;
      border: 1px solid #f50673;
      background-image: none;
      background-color: #fff !important;
    }
  }
}


@media screen and (max-width: 900px) {

  .nav {
    background-color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;

    ul li {
      display: block;
      padding-left: 0px;
      font-size: 20px;
      padding-bottom: 5px;
    }


    .nav-items {
      display: none;
    }

    .nav-icons {
      display: flex;
    }

  }

  .bm-burger-button {
    display: block !important;
  }

}

/* Icon 1 */

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  height: 30px;

}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #000;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}



#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 30px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  margin:auto;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: black;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/* Icon 4 */

#nav-icon4 {
}

#nav-icon4 span:nth-child(1) {
  top: 8px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 16px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 24px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 29px;
  left: auto;
}


/* RESPONSIVE - MENU */
/* Position and sizing of burger button */
.bm-burger-button {
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;

  button {
    left: auto !important;
    width: auto !important;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

.bm-menu-wrapper {
  overflow: hidden;
}
.bm-menu-wrap {
  height: 100vh!important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  margin-right: 26px;
  margin-top: 5px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  height: 24px !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  width: 100vw;
  margin-top: -10px;
  overflow: hidden;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  opacity: 0 !important;
}

.bm-icon {
  margin-top: 10px;
}

.side-custom-menu {
  height: 100%;

  .responsive-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .section-logo {
      .logo {

        img {
          height: 140px;
        }
      }
      .adrena {

        img {
          width: 240px;
          margin-top: 25px;
        }
      }
      margin-bottom: 11%;
    }

    .section-login {
      margin-bottom: 11%;
      width: 100%;
      display: block;

      a {
        margin: auto;
      }

      .partner {
        padding: 18px 0 18px 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        // font-family: 'ZonaPro-SemiBold', sans-serif;
        font-size: 14px;
        // font-weight: 800;
        text-align: center;
        border-radius: 4px;
        color: #f50673 !important;
        border: 1px solid #f50673;
        background-image: none;
        background-color: #fff !important;
        width: 240px;
        margin: 5% auto auto auto;
      }

      .login {
        width: 240px;
        padding: 18px 0 18px 0;
        font-size: 14px;
        // font-weight: 800;
        margin-top: 0;
        // font-family: 'ZonaPro-SemiBold', sans-serif;
      }
    }

    .section-other {
       .links {
        &:first-child {
          margin-top: 0;
        }
      }
    }

  }

  .links {
    margin-top: 12.5%;
    // font-family:   sans-serif;
    display: grid !important;
    font-size: 18px;
    // font-weight: 500;
    text-align: center;
    color: #00001f;

    &:active {
      color: whitesmoke;
    }

  }

}
