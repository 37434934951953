.PageLoader {
  $loaderHeight: 250px;
  $loaderWidth: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;

  .loader {
    display: flex;
    align-items: center;
    background-color: transparent;
    height: $loaderHeight;
    width: $loaderWidth;

    img {
      display: block;
      margin: auto;
    }

    .loader-ball {
      background-color: black;
      border-radius: $loaderHeight;
      display: inline-block;
      height: 100%;
      width: $loaderHeight;
      margin: 3px;

      &:first-child {
        animation: load1 1s ease-in-out infinite;
        animation-delay: -.32s;
      }

      &:nth-child(2) {
        animation: load2 1s ease-in-out infinite;
        animation-delay: -.16s;
      }

      &:last-child {
        animation: load3 1s ease-in-out infinite;
      }


    }
  }

  @keyframes load1 {
    0% {
      opacity: 1;
    }

    33% {
      opacity: .66;
    }

    66% {
      opacity: .33;
    }

    100% {
      opacity: 0;
    }
  }


  @keyframes load2 {
    0% {
      opacity: .66;
    }
    33% {
      opacity: .33;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  @keyframes load3 {
    0% {
      opacity: .33;
    }

    33% {
      opacity: 0;
    }

    66% {
      opacity: 1;
    }

    100% {
      opacity: .66;
    }
  }

}