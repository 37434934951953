.Register {

  .modal-content {
    width: 460px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 60px 0 rgba(0, 27, 35, 0.2);
    padding: 33px;

    .alert {
      margin-left: 33px;
      margin-right: 33px;
    }

    .btn-social {
      font-size: 14px;
      width: 100%;
      height: 66px;
      letter-spacing: 0.4px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    .form-register {
      width: 360px;
    }

    .facebook-login {
      display: flex;
      align-items: center;

      span {
        width: 100%;
      }
    }

    .separator-or {
      margin: 33px;


      hr {
        width: 80%;
        background-color: #EBEBEB;
        height: 1px;
        border: 0;
      }
    }

    .margin-register {
      margin-left: 33px;
      margin-right: 33px;
    }

    .other-links {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-bottom: 0;
      margin-top: 25px;

      div {
        flex: 1 1 auto;
        background: transparent;
        // font-family:   sans-serif;
        color: #9696a2;
        border: 0;
        cursor: pointer;

        &:first-child {
          text-align: left;
          margin-left: 33px;
        }

        &:last-child {
          text-align: right;
          margin-right: 33px;
        }
      }

      @media all and (max-width: 390px) {
        flex-direction: column;

        div {
          margin: 10px 0 0 0 !important;
        }
      }

    }

  }


}

.ReactModal__Content {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 27, 35, 0.2);
  border: 0 !important;
  padding: 0 !important;

  .modal-content {
    box-shadow: 0 0 60px 0 rgba(0, 27, 35, 0.2);
    margin: 0 60px 60px 60px;
  }

}

.ReactModal__Overlay--after-open {
  z-index: 9999999;
}

.modal-cookie {
  top: 50%;
  left: 25%;
  right: 25%;
  bottom: auto;
  width: 100%;
  transform: translate(0%, 110%);
  display: flex;
  justify-content: center;
  background: none;
  box-shadow: none;
  z-index: 999;
  background-color: #ffffff00;
  outline: #ffffff00;
}

.container-modal-cookie {
  background: #fefefe;
  padding: 2%;
  border-radius: 2%;
  border: 1px solid #ccc;
}

@media all and (max-width: 1024px) {
  .modal-cookie {
    inset: -50% -27% auto;
    transform: translate(5%, 88%);
    height: 40% !important;
    width: 90% !important;
  }

  .container-content {
    display: flex;
    flex-direction: column;
  }
}

@media all and (max-width: 500px) {


  .modal-content {
    width: 100% !important;
  }

  .ReactModal__Content {
    .modal-content {
      margin-left: 0 !important;
    }
  }

  .close-modal {
    margin-right: 3px;
  }

  .ReactModal__Content--after-open {
    height: 100%;
    width: 100%;
  }

  .box-modal {
    width: 100%;
  }

  .btn-facebook .fa {
    font-size: 20px;
    padding-left: 0;
    margin-left: -12px;
    padding-right: 10px;
  }

}

@media all and (max-width: 350px) {
  .ReactModal__Content .modal-content {
    min-height: inherit;
    margin: 0;
  }

}