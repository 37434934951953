$grey-background: #fafafa;

.footer {
  background-color: $grey-background;
  padding-bottom: 50px;

  &.minimal {
    padding-bottom: 2px;
    padding-top: 2px;
  }
}

.footer-only {
  // margin-top: 130px;
  margin-top: 80px;
}

.footer-only {
  .footer-end {
    // margin-top: 100px !important;
    margin-top: 60px !important;
  }
}

.p-minimal {
  font-size: 6px; 
  text-transform: uppercase; 
  // text-align: center; 
  letter-spacing: 0.8px;
}

.p-minimal span { 
  display: inline-block;
  vertical-align: middle;
  padding-right: 12px;
  font-family: sans-serif;
  color: #333; 
  float: right;
}

.logo-adrena-minimal { 
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  width: 78px;
  height: 14px; 
}

.footer-box {
  display: flex;
}

.footer-item-doc {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.footer-item-doc span {
  margin-left: 30px;
  font-size: 9px;
}

.footer-item-logo {
  width: 50%;
}

@media (max-width: 768px) {
  .footer-box {
    flex-direction: column;
  }

  .footer-item-doc, .footer-item-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .p-minimal span {
    float: unset;
  }
}