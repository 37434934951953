@import url('./fa.css');



@font-face {
  font-family: 'ZonaPro';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro-Bold';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}



/*

@font-face {
  font-family: 'Quicksand';
  src: local('Sans-Serif');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand-Bold';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: bold;
}

@font-face {
  font-family: 'Quicksand-Regular';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'Quicksand-Medium';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro-ExtraBold';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro-SemiBold';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro-Black';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro-Bold';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro-Light';
  font-display: swap;
  src: local('Sans-Serif');
  font-weight: normal;
}

@font-face {
  font-family: 'ZonaPro-RegularItalic';
  font-display: swap;
  src: local('Sans-Serif');
}

*/