/*
  Neste arquivo contem as classes necessárias para criacao do grid
 */

.wrapper {
  max-width: 80%;
  //height: 100%;
  margin: 0 auto;
  //border: 1px solid red;
}

*, *:before, *:after {
  box-sizing: border-box;
}

@media all and (max-width: 1130px) {
  .wrapper {
    margin-left: 10%;
    margin-right: 10%;
  }
}

/*Basic Grid Styles*/
.Grid {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
//
//@media all and (max-width: 500px) {
//  .Grid {
//    align-items: center;
//    justify-content: center;
//  }
//}

.Grid-column {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
}

.Grid-row {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap
}

.Grid-cell {
  flex: 1 1;
}

// Grid Style Modifiers

/* With gutters*/
$gutter: 30px;

.Grid--gutters {
  margin-left: -$gutter;
  .Grid-cell {
    padding-left: $gutter;
  }
  .Grid--nested {
    .Grid-cell:first-of-type {
      .Demo {
        margin-right: 1em;
      }
    }
  }
}
.Gutter-10 {
  margin: -10px;
  .Grid-cell {
    padding: 10px;
  }
}


/* Justify per row*/
.Grid--right {
  justify-content: flex-end;
}
.Grid--center {
  justify-content: center;
}
.Grid--left {
  justify-content: flex-start;
}

/* Alignment per row */
.Grid--top {
  align-items: flex-start;
}
.Grid--bottom {
  align-items: flex-end;
}
.Grid--center {
  align-items: center;
}
.Grid--left {
  align-items: center;
}

.Grid--stretch {
  align-items: stretch;
}

/* Alignment per cell */
.Grid-cell--top {
  align-self: flex-start;
}
.Grid-cell--bottom {
  align-self: flex-end;
}
.Grid-cell--center {
  align-self: center;
}

.Grid-0 > .Grid-cell{
  flex: 0;
}

.Grid-0 {
  flex: 0;
}

.flex-grow-7 {
  flex-grow: 7;
}

// Menu
.navigation {
  list-style: none;
  margin: 0 0 1em;
  border-radius: 3px;

  display: flex;

  -webkit-flex-flow: row wrap;
  justify-content: flex-end;
  a {
    text-decoration: none;
    display: block;
    padding: 1em;
    color: #333;
    &:hover {
      border-radius:3px;
    }
  }
  &:hover {
    background: none;
  }
}

@media all and (max-width: 800px) {
  .navigation {
    justify-content: space-around;
  }
}

@media all and (max-width: 600px) {
  .navigation {
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    padding: 0;
    a {
      text-align: center;
      padding: 10px;
      border-top: 1px solid rgba(255,255,255,0.3);
      border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    li:last-of-type a {
      border-bottom: none;
    }
  }
}


/*===========================================*/
/* Base classes for all media - Mobile first */
.Grid--cols-2 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-3 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-4 > .Grid-cell {
  flex: 0 0 100%;
}

.Grid--cols-6 > .Grid-cell {
  flex: 0 0 calc(50% - #{$gutter});
}

.Grid--cols-12 > .Grid-cell {
  flex: 0 0 calc(33.3333% - #{$gutter});
}

.Grid-md-12 {
  flex: 100%;
}

.Grid--holly-grail {
  .aside, .main {
    flex: 1 100%;
  }
}

/* One of -- columns*/
.Grid--1of2 > .Grid-cell,
.Grid--1of4 > .Grid-cell:first-of-type,
.Grid--1of3 > .Grid-cell:first-of-type{
  flex: 0 0 100%;
}
.Grid--1of6 > .Grid-cell:first-of-type {
  flex: 0 0 50%;
}

.Grid--fit > .Grid-cell {
  flex: 1;
}
.Grid--full > .Grid-cell {
  flex: 0 0 100%;
}

/* Tablet (medium) screens */
@media (min-width: 30em) {
  .Grid--cols-4 > .Grid-cell {
    flex: 0 0 calc(50% - #{$gutter});
  }
  .Grid--cols-6 > .Grid-cell {
    flex: 0 0 calc(33.3333% - #{$gutter});
  }
  .Grid--cols-12 > .Grid-cell {
    flex: 0 0 calc(16.6666% - #{$gutter});
  }
  .Grid--holly-grail {
    .aside {
      flex: 1 calc(25% - #{$gutter});
    }
  }
  .Grid--1of2 > .Grid-cell {
    flex: 0 0 50%;
  }
  .Grid--1of6 > .Grid-cell:first-of-type {
    flex: 0 0 30%;
  }
  .Grid--1of4 > .Grid-cell:first-of-type {
    flex: 0 0 50%;
  }
  .Grid--1of3 > .Grid-cell:first-of-type {
    flex: 0 0 100%;
  }
}

/* Large screens */
@media (min-width: 48em) {
  .Grid--cols-2 > .Grid-cell,
  .Grid--cols-3 > .Grid-cell,
  .Grid--cols-4 > .Grid-cell,
  .Grid--cols-12 > .Grid-cell{
    flex: 1;
  }
  .Grid--holly-grail {
    .main    { flex: 2; }
    .aside   { flex: 1; }
    .aside-1 { order: 1; }
    .main    { order: 2; }
    .aside-2 { order: 3; }
  }
  .Grid--1of2 > .Grid-cell {
    flex: 0 0 50%;
  }
  .Grid--1of6 > .Grid-cell:first-of-type {
    flex: 0 0 16.6666%;
  }
  .Grid--1of4 > .Grid-cell:first-of-type {
    flex: 0 0 25%;
  }
  .Grid--1of3 > .Grid-cell:first-of-type {
    flex: 0 0 30%;
  }
  .Grid--gutters.Grid--nested {
    .Grid-cell:first-of-type {
      .Demo {
        margin-right: 0;
      }
    }
  }
}

// Contents classes
.content-1of1::before { content: "1"; }
.content-1of2::before { content: "1/2"; }
.content-1of3::before { content: "1/3"; }
.content-1of4::before { content: "1/4"; }
.content-1of6::before { content: "1/6"; }
.content-1of12::before { content: "1/12"; }


