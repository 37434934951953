.ContactLinks {
  display: flex;
  width: 40%;
  margin-top: 30px;
  margin-left: 30%;


  a {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 46px;

    img {
      height: 20px;
    }

    .fa {
      font-size: 24px;
      color: #f50673;
    }
  }

  @media all and (max-width: 768px) {

    margin: {
      left: 30%;
      right: 30%;
    }

    div {
      flex-basis: 50%;

      a {
        margin: 0;
        height: 25px;

        img {
          height: 25px;
        }
      }
    }
  }

  @media all and (max-width: 500px) {
    margin-top: 60px;

    a {
      .fa {
        font-size: 28px;
      }
    }
  }

}