.MainSearchContainer {
  flex: 3;
  text-align: center;
  position: relative;

  .main-search-content {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 15px 0 rgba(50, 50, 50, 0.2);
    padding: 0 1%;

    .fa {
      padding: 12px 5px 12px 5px;
      font-size: 22px;
    }

    .search-field {
      color: #b8b8bc;
      flex: 2;

      input {
        width: 90%;
      }

      input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        margin-top: 5px;
      }

      input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        margin-top: 5px;
      }

      input::-ms-input-placeholder { /* Microsoft Edge */
        margin-top: 5px;
      }
    }

    .search-btn {
      color: #f50673;
    }
  }
}

.main-search-mobile {
  .fa-search {
    padding: 5px 15px;
    font-size: 20px;
  }
}

.search-field-mobile input {
  max-width: 75%;
  margin-left: 5%;
  text-overflow: ellipsis;
}

.main-search {
  height: auto;

  .input-search {
    // font-family: sans-serif;
    font-size: 16px;
    // font-weight: 500;
    line-height: 2.75;
    text-align: left;
    color: #00001f;
    text-overflow: ellipsis;
  }

  .items-search {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
    z-index: 9999;
    opacity: 1;
  }

  .SingleDatePickerInput {
    border: 0;
  }

  .DateInput {
    padding: 0;
  }

  .DateInput__input, .DateInput {
    color: #b8b8bc !important;
    // font-family: sans-serif;
    font-size: 14px !important;
    // font-weight: 500;
    line-height: 3.14;
    text-align: left;
  }

  .SingleDatePicker__picker {
    z-index: 2;
  }

  .search-city {
    // font-family: sans-serif;
    font-size: 14px;
  }

  .btn-call-action {
    padding: 4px 8px 4px 8px;
  }

  @media all and (max-width: 650px) {
    .list-search-city li {
      // margin-left: 32px !important;
    }
  }

  .btn-call-action {
    border-radius: 0px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }


  .list-search {
    // box-shadow: 0px 15px 40px 0 rgba(0, 0, 0, 0.3);
    z-index: 9;
    // background: #fff;
    position: absolute;
    margin-top: 5px;
    background: #fff;
    left: 0;
    width: 100%;
    text-align: left;
    list-style: none;
    border-radius: 0px 0px 4px 4px;
    border: 0px solid;
    padding-left: 0px;

    li {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-left: 66px;
      margin-right: 66px;
      border-bottom: 1px solid rgba(153, 153, 153, .2);
      font: {
        family: sans-serif;
        size: 16px;
      }
      text-align: left;
      color: rgba(0, 0, 31, 0.6);
      cursor: pointer;
    }

    .category-tag {
      color: #b8b8bc;
      margin-left: -66px;
      width: 66px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  .location-addon {
    padding-right: 30px;
    justify-content: flex-start;
  }

  .list-search-city {
    // box-shadow: 0px 15px 40px 0 rgba(0, 0, 0, 0.3);
    z-index: 9;
    // background: #fff;
    position: absolute;
    width: 100%;
    margin-top: 0px;
    text-align: left;
    list-style: none;
    border-radius: 0px 0px 4px 4px;
    border: 0px solid;
    padding-left: 0px;

    li {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-left: 66px;
      margin-right: 66px;
      border-bottom: 1px solid rgba(153, 153, 153, .2);
      font: {
        family: sans-serif;
        size: 16px;
      }
      text-align: left;
      color: rgba(0, 0, 31, 0.6);
      cursor: pointer;
    }
  }

  .remove-date {
    position: absolute;
    right: 11px;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    // font-family: Quicksand-Medium, sans-serif;
    color: #b8b8bc;
    cursor: pointer;
  }

  @media all and (max-width: 370px) {
    .input-search {
      width: 100px;
    }
  }

}

/* Search Page */
.search-page {
  .main-search {
    //width: 1052px;
    margin-top: 59px;
    bottom: auto;
    position: relative;
  }
}

.modal-content {
  position: relative;

  .main-search {
    width: 100%;
    bottom: auto;
    box-shadow: none;

    .list-search-city {
      width: 100%;
    }

    .InputAddOn-item, .location-addon {
      justify-content: flex-start !important;
      border-bottom: 1px solid rgba(153, 153, 153, 0.2);
    }

    .search-city {
      display: block;
      width: 200px;
    }

    .list-search-city {
      display: block !important;
    }

    .list-search-city li {
      margin-left: 52px !important;
    }
  }
}

.search-modal {
  min-height: 480px !important;
}


