$green-hover: #00D900;
$disabled: #999;
$dark-grey: #A6A6A6;
$light-grey: #E5E5E5;


$black: #000;
$white: #fff;
$textcolor: #333;

$textgrey: #666;
$lightgrey: #ccc;
$darkgrey: #111;

$pink: #f50673;
$pink-hover: #bc0048;
$green: #0f0;

$fontbold: 'ZonaPro-Bold', sans-serif;