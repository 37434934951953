.newsletter {
  width: 923px;
  min-height: 434px;
  height: auto;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.06);
  margin-top: -237px;

  .group-input {
    margin-bottom: 0px;
  }

  .news-cad {
    margin-left: 15%;
    margin-right: 15%;
    height: 50px;
    margin-top: 50px;
    margin-bottom: 20px;

    div .btn-call-action{
      margin-left:50px;
      width: 141px;
      margin-bottom: 8px;
      margin-top: -8px;
    }

  }

  .title {
    // font-family:   sans-serif;
    font-size: 20px;
    // font-weight: bold;
    line-height: 1.8;
    margin-top: 30px;
    text-align: center;
    color: #00001f;
  }

  .description {
    padding-left: 15%;
    padding-right: 15%;
    margin-top: 27px;
    font-size: 16px;
    // font-weight: 500;
    line-height: 1.63;
    text-align: center;
    color: #00001f;
  }
  .register-image {
    width: 259px;
    height: 139px;
    margin-left: 30px;
    margin-bottom: 20px;
    z-index: 9;
  }

  .register-message {
    // font-weight: 900;
    background-color: #fafafa;;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
    margin-left: -100px;
    padding-left: 120px;
    z-index: 0;
  }

  .green {
    color: #47c747;
  }

  .Grid-cell {
    .Grig-cell:first-child {
      flex-grow: 7;
    }
  }

  @media (max-width: 800px) {
    .Grid-cell {
      .Grid-cell{
        flex-basis: 100%;
      }
      .Grid-cell:last-child {
        justify-content: center;
      }

    }
    .news-cad {
      margin: 50px 10% 60px 10%;
      padding: 0;
    }

    .news-cad div .btn-call-action {
      margin-top: 20px;
      margin-left: 0;
      width: 100%;
    }
  }

  @media (max-width: 728px) {
    .register-image {
      margin-left: 25%;
      display: none;
    }

    .register-message {
      padding: 20px;
      margin-left: 15%;
      margin-right: 15%;
    }
  }

  @media (max-width: 460px) {
    .register-image {
      margin-left: 15%;
    }
  }


}

@media all and (max-width: 923px) {
  .newsletter {
    width: 100%;
    height: auto;
  }
}