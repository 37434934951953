/*
  Neste arquivo contém classes utilitárias para Layouts, como floats, margins e afins...
 */
$label-input: #9696a2;
$main-search-width: 855px;

body {
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: white;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

#root {
  width: 100vw;
  max-width: 100vw;
}

/* default */
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer !important;
}

input[type=text],
input[type=password],
input {
  box-sizing: border-box;
}

input:focus {
  outline: none !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

button {
  cursor: pointer;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

/* Video banner */
#vid {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 630px;
  z-index: -1000;
  overflow: hidden;
}



/* Utils */
.float-right {
  float: right;
}

.float-left {
  float: left;
}

.full-width {
  width: 100%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center !important;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.display-flex {
  display: flex !important;
}

.display-inline-flex {
  display: inline-flex !important;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.fixed {
  position: fixed !important;
}

.top {
  top: 0;
}

.top-0 {
  margin-top: 0px !important;
}

.bottom-0 {
  margin-bottom: 0px !important;
}

.opacity-0 {
  opacity: 0;
  cursor: default !important;
}

.shadow-0 {
  box-shadow: 0 0 0 0 !important;
}

.unclick {
  cursor: not-allowed !important;
}

.vr {
  display: inline;
  height: 50%;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1);
  margin: 5px
}

.top-10 {
  margin-top: 10px;
}

img {
  //display: block;
  //max-width: 100%;
  height: auto;
}

/* Buttons */

.btn {
  // font-family: sans-serif;
  border: 0px;
  cursor: pointer;
}

.btn-social {
  border-radius: 4px;
}

.btn-lg {
  font-size: 18px !important;
  padding: 25px 50px 25px 50px !important;
}

.btn-call-action {
  padding: 5px 4px;
}

.btn-inner-card {
  //margin-left: 55px;
  //margin-right: 55px;
  text-align: center;
}

/* Modal */

.close-modal {
  color: #FD8283;
  float: right;
  right: 75px;
  top: -2px;
  margin-right: 60px;
  cursor: pointer;
}

.box-modal {
  box-shadow: 0 0 60px 0 rgba(0, 27, 35, 0.2);
}

/* Forms */

.main-search {
  //bottom: -34px;
  //position: absolute;
  //width: $main-search-width;
  height: 68px;
  // border-radius: 4px;
  // background-color: #ffffff;
  // box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
  border: 0px;

  // .btn-call-action {
  //   width: 159px;
  // }

  input {
    border: 0px;
  }

  *::-webkit-input-placeholder {
    font-size: 16px;
    color: #b8b8bc;
  }

  *:-moz-placeholder {
    /* FF 4-18 */
    font-size: 16px;
    color: #b8b8bc;
  }

  *::-moz-placeholder {
    /* FF 19+ */
    font-size: 16px;
    color: #b8b8bc;
  }

  *:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 16px;
    color: #b8b8bc;
  }

}

@media (max-width: $main-search-width) {
  .main-search {
    width: 100%;
  }
}

.InputAddOn {
  display: flex;
}

.InputAddOn-field {
  flex: 1;
  /* field styles */
}

.InputAddOn-item {
  /* item styles */

  input {
    // font-family:   sans-serif;
    font-size: 14px;
    color: #00001f;
  }

  img {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 4px;
  }

  .fa {
    font-size: 16px;
    color: #b8b8bc;
    padding-left: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 4px;
  }
}

/* form starting stylings ------------------------------- */
.group-input {
  position: relative;
  margin-bottom: 45px;

  input,
  textarea {
    background: transparent;
    font-size: 18px;
    padding: 2px 2px 2px 1px;
    display: block;
    border: none;
    border-bottom: 1px solid #dddddd;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  .autocomplete {
    position: absolute;
    z-index: 3;
    margin-top: 0px;
    width: 100%;
    padding-left: 0px;
    list-style: none;
    background: white;
    padding-top: px;
    border: 1px solid rgba(0, 0, 0, .2);

    li {
      padding-top: 5px;
    }
  }

  /* LABEL ======================================= */
  label {
    // font-family:   sans-serif;
    // font-weight: 500;
    color: $label-input;
    font-size: 16px;
    position: absolute;
    pointer-events: none;
    top: -10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    // letter-spacing: 0.5px;

    @media all and (max-width: 479px) {
      font-size: 14px !important;
    }
  }

  /* active state */
  input:focus~label,
  input:valid~label,
  textarea:focus~label,
  textarea:valid~label {
    top: -20px;
    font-size: 14px;
    color: #5264AE;
  }

  &.error {
    input {
      border-bottom: 1px solid red;
    }

    label {
      color: red;
    }
  }

  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
  }

  .bar:before,
  .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #5264AE;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus~.bar:before,
  input:focus~.bar:after,
  textarea:focus~.bar:before,
  textarea:focus~.bar:after {
    width: 50%;
  }
}

/* Selects */
.main-select {
  background: url(../../assets/img/icons/down-arrow.png) !important;
  background-position: right 21px top 50% !important;
  background-repeat: no-repeat !important;
  -webkit-appearance: none !important;
}

/* Cards */

.Login-card {
  //div {
  //  margin-top: 50px;
  //}
}