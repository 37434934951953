.btn {
    // font-family: 'ZonaPro-SemiBold', sans-serif;
    font-size: 14px;
    // font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.primary {
        color: #ffffff;
        background-image: linear-gradient(290deg, #a831ef, #fb29fc, #fd29fc);
        border-radius: 4px;
    }

    &.secondary {
        color: #f50673;
        background: #ffffff;
        border-radius: 4px;
        border: solid 1px #f50673;
    }

    &.black {
        color: #000000;
        border-color: #000000;
        border-radius: 4px;
        background: white;
    }

    &.green {
        color: #ffffff;
        border-radius: 4px;
        background: linear-gradient(270deg, #43B443 0%, #4EC94E 100%);
    }

    &.blue {
        border-radius: 4px;
        border: solid 2px #206db2;
        color: #206db2;
    }

    &.disabled {
        color: #ffffff;
        border-radius: 4px;
        background: linear-gradient(270deg, #DDDDDD88 0%, #CCCCCC88 100%);
    }

    &.sm {
        width: 152px;
        height: 50px;
    }

    &.x-sm {
        width: 58px;
        height: 38px;

        span {
            font-size: 20px;
        }
    }

    &.md {
        padding: 1.5rem 1.5rem;
    }

    &.lg {
        width: 295px;
        height: 65px;

        @media all and (max-width: 479px) {
            width: 152px;
            height: 50px;
        }
    }

    &.full {
        width: 100%;
        height: 66px;

        @media all and (max-width: 479px) {
            height: 50px;
            font-size: 12px;
        }
    }

    &.half {
        width: 50%;
    }

    .button-icon {
        position: absolute;
        left: 15px;
        font-size: 22px;
    }
}