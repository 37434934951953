.ForgotPassword {
  .modal-content {
    width: 460px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 60px 0 rgba(0, 27, 35, 0.2);
    padding: 33px;

    .alert {
      margin-left: 33px;
      margin-right: 33px;
    }

    .btn-social {
      font-size: 14px;
      width: 100%;
      height: 66px;
      letter-spacing: 0.4px;
      padding-top: 15px;
    }

    .form-register {
      width: 360px;
    }

    .facebook-login {
      display: flex;
      align-items: center;

      span {
        width: 100%;
      }
    }

    .separator-or {
      margin: 33px;

      hr {
        width: 80%;
        background-color: #EBEBEB;
        height: 1px;
        border: 0;
      }
    }

    .margin-register{
      margin-left: 33px;
      margin-right: 33px;
    }

    .other-links {
      display: flex;
      align-items:center;
      justify-content: center;
      width: 100%;
      margin-bottom: 0;
      margin-top: 25px;

      div {
        flex: 1 1 auto;
        background: transparent;
        // font-family:   sans-serif;
        color: #9696a2;
        border: 0;
        cursor: pointer;

        &:first-child {
          text-align: left;
          margin-left: 33px;
        }
        &:last-child {
          text-align: right;
          margin-right: 33px;
        }
      }
    }


  }
}