.Card {
  transition: all 0.25s ease-in-out 0s;

  &:hover {
    margin: -15px;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 4px;
  }
}